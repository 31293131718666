<template>
  <div>
    <kn-form-subtitle title="Agregar cuenta bancaria" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Nombre de cuenta:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="bankAccount.nombre_cuenta"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Número de cuenta:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="bankAccount.numero_cuenta"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Balance:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="bankAccount.balance"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Banco:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="bankAccount.id_banco"
            dense
            outlined
            :rules="[rules.required]"
            :items="banks"
            item-text="dato"
            item-value="id"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de cuentas bancarias"
      divider-position="bottom"
      :showDownloadBtn="true"
      :loadingDownload="loadingDownload"
      downloadTitle="Descargar reporte de movimientos de cuenta"
      @downloadAction="downloadExcelAccountMovements"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in bankAccountsActive">
          <v-list-item :key="item.nombre_cuenta">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === bankAccount.id"
                  class="mb-0 pb-0"
                  v-model="bankAccount.nombre_cuenta"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_cuenta"
                />
                <v-list-item-subtitle
                  class="text--primary"
                  v-text="`Número de cuenta: ${item.numero_cuenta}`"
                />
                <v-list-item-subtitle v-text="`Balance: $${item.balance}`" />
                <template>
                  <v-data-table
                    v-if="showMovements && item.id === selectedBankAccount.id"
                    dense
                    :headers="movementsHeaders"
                    :items="accountMovements"
                    item-key="id"
                    loading-text="Cargando... Espera por favor"
                    no-data-text="Aún no hay registros"
                    no-results-text="No se encontraron coincidencias"
                    :footer-props="{
                      itemsPerPageText: 'Elementos por página'
                    }"
                  >
                    <template v-slot:item.fecha_elaboracion="{ item }">
                      {{ item.fecha_elaboracion.substring(0, 10) }}
                    </template>
                    <template v-slot:item.movimiento="{ item }">
                      <span :class="item.entrada_dinero ? 'success--text' : 'error--text'">
                        {{ item.entrada_dinero ? 'Entrada' : 'Salida' }}
                      </span>
                    </template>
                    <template v-slot:item.monto="{ item }">
                      <span :class="item.entrada_dinero ? 'success--text' : 'error--text'">
                        ${{ item.monto }}
                      </span>
                    </template>
                  </v-data-table>
                </template>
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="showMovements && item.id === selectedBankAccount.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Cerrar movimientos"
                      @click="closeMovements()"
                    >
                      <v-icon color="primary">
                        mdi-delta
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <!-- <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Cancelar"
                      @click="clear"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Guardar cambios"
                      @click="save"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col> -->
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Ver movimientos"
                      @click="openMovements(item)"
                    >
                      <v-icon color="primary">
                        mdi-details
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <!-- <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Editar"
                      @click="edit(item)"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Eliminar"
                      @click="deleteInline(item.id)"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < bankAccountsActive.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [validationFormMixin,fileUtilsMixin],
  data() {
    return {
      errors: [],
      valid: true,
      bankAccount: {
        id: 'NA', // No asignado
        nombre_cuenta: null,
        numero_cuenta: null,
        balance: null,
        id_banco: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      selectedBankAccount: {},
      bankAccounts: [],
      banks: [],
      movements: [],
      toUpdate: false,
      movementsHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion' },
        { text: 'Movimiento', value: 'movimiento' },
        { text: 'Monto', value: 'monto' },
      ],
      showMovements: false,
      loadingDownload: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    bankAccountsActive() {
      return this.bankAccounts.filter(account => account.estatus_sistema === true)
    },
    accountMovements() {
      return this.movements.filter(movement => movement.cuenta.id === this.selectedBankAccount.id)
    },
    invalidFields() {
      return this.objectHasNulls(this.bankAccount)
    }
  },
  async created() {
    this.bankAccount.id_autor = this.userData.id
    this.bankAccount.id_institucion_educativa = this.institutionId
    this.banks = await this.fetchBanksByEI(this.institutionId)
    this.bankAccounts = await this.fetchBankAccountByEI(this.institutionId)
    this.movements = await this.fetchMovementsByEI(this.institutionId)
  },
  methods: {
    async fetchBankAccountByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/cuanta-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener cuentas bancarias por institucion educativa', error);
      }
    },
    async fetchMovementsByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/movimientos-cuenta-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener movimientos de cuentas bancarias por institucion educativa', error);
      }
    },
    async fetchBanksByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/banco-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener banco por institucion educativa', error);
      }
    },
    async createBankAccount() {
      try {
        const response = await api.post('/administracion/crear-cuenta', {
          nombre_cuenta: this.bankAccount.nombre_cuenta,
          numero_cuenta: this.bankAccount.numero_cuenta,
          balance: this.bankAccount.balance,
          id_banco: this.bankAccount.id_banco,
          id_autor: this.bankAccount.id_autor,
          id_institucion_educativa: this.bankAccount.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la cuenta bancaria.')
        }
      } catch (error) {
        console.error('Error al intentar crear cuenta bancaria', error)
        this.errors.push('Error al intentar crear cuenta bancaria')
      }
    },
    async updateBankAccount() {
      try {
        const response = await api.patch('/administracion/update-cuenta', {
          id: this.bankAccount.id,
          nombre_cuenta: this.bankAccount.nombre_cuenta,
          numero_cuenta: this.bankAccount.numero_cuenta,
          balance: this.bankAccount.balance,
          id_banco: this.bankAccount.id_banco,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar el tipo de gasto')
        }
      } catch (error) {
        console.error('Error al intentar actualizar cuenta bancaria', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteBankAccount(accountId) {
      try {
        const response = await api.post('/administracion/delete-cuenta', {
          id_cuenta: accountId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la cuenta')
        }
      } catch (error) {
        console.error('Error al intentar eliminar cuenta bancaria', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateBankAccount()
        this.toUpdate = false
      } else {
        await this.createBankAccount()
        this.clear()
      }
      this.bankAccounts = await this.fetchBankAccountByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.bankAccount = {
        id: 'NA', // No asignado
        nombre_cuenta: null,
        numero_cuenta: null,
        balance: null,
        id_banco: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.bankAccount = {...item}
      this.bankAccount.id_banco = item.banco.id
      // console.log('Item a editar:', this.bankAccount);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteBankAccount(itemId)
      this.bankAccounts = await this.fetchBankAccountByEI(this.institutionId)
      this.clear()
      this.bankAccount.id = null
    },
    openMovements(item) {
      this.selectedBankAccount = {...item}
      this.showMovements = true
    },
    closeMovements() {
      this.selectedBankAccount = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.showMovements = false
    },
    async downloadExcelAccountMovements () {
      try {
        this.loadingDownload = true
        const fileName = 'movimientos_cuentas.xls'
        const response = await api.get('/administracion/reporte-movimientos-cuentas', {
          responseType: 'blob'
        })
        const responseData = await response.data
        console.log("Blob?", responseData);
        if (response.status === 200) {
          this.downloadFileFromBlob(responseData, fileName)
        }
      } catch (error) {
        console.error("Ocurrión un error al intentar descargar el reporte de ingresos")
      } finally {
        this.loadingDownload = false
      }
    }
  },
}
</script>

<style>

</style>